import styled from "styled-components";

export const SignatureGeneratorContainer = styled.div`
  padding-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  min-height: 100vh;
`;

export const SignatureContainer = styled.div`
  width: 506px;
  margin: auto;
  margin-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Button = styled.button`
  margin-top: 40px;
`;
