/* eslint-disable react/jsx-filename-extension */
import React from "react";
import SignatureMobileCareForm from "./components/mobilecare/SignatureForm";
import SignatureHealthGroupForm from "./components/healthGroup/SignatureForm";
import { SignatureFormContainer, Title, InputContainer, Label } from "./styles";

const SignatureTypeComponent = ({
  handleOnSubmit,
  handleChange,
  inputs,
  signatureTypes,
  signatureSelectedOption,
  handleSignatureType,
}) => {
  return (
    <SignatureFormContainer>
      <Title>How to Generate Your Signature:</Title>
      <Label>1. Fill in your personal information in each field.</Label>
      <Label>
        2. Upload an image from your device (preferably a square image under 50
        KB).
      </Label>
      <Label>
        3. Once your signature is generated, click the Copy button and paste it
        into your Gmail settings.
      </Label>
      <Title>Signature form</Title>
      <InputContainer>
        <Label for="siganture-type">Please, select signature type:</Label>
        <select
          id="siganture-type"
          name="siganture-type"
          value={signatureSelectedOption}
          onChange={(event) => handleSignatureType(event.target.value)}
          required
        >
          <option value="" selected />
          <option value={signatureTypes.healthGroup}>Life Health Group</option>
          <option value={signatureTypes.mobileCare}>Life Mobile Care</option>
        </select>
      </InputContainer>
      {signatureSelectedOption !== "" &&
        (signatureSelectedOption === signatureTypes.mobileCare ? (
          <SignatureMobileCareForm
            handleOnSubmit={handleOnSubmit}
            handleChange={handleChange}
            inputs={inputs}
          />
        ) : (
          <SignatureHealthGroupForm
            handleOnSubmit={handleOnSubmit}
            handleChange={handleChange}
            inputs={inputs}
          />
        ))}
    </SignatureFormContainer>
  );
};

export default SignatureTypeComponent;
