/* eslint-disable react/jsx-filename-extension */
import React from "react";
import {
  Title,
  Form,
  InputContainer,
  Label,
  Input,
  Button,
} from "../../styles";

const SignatureMobileCareForm = ({ handleOnSubmit, handleChange, inputs }) => {
  return (
    <Form onSubmit={handleOnSubmit}>
      <Title>Life Mobile Care Form</Title>
      <InputContainer>
        <Label for="name">Name</Label>
        <Input
          id="name"
          name="name"
          value={inputs.name || ""}
          onChange={handleChange}
          required
        />
      </InputContainer>
      <InputContainer>
        <Label for="role">Role</Label>
        <Input
          id="role"
          name="role"
          value={inputs.role || ""}
          onChange={handleChange}
          required
        />
      </InputContainer>
      <InputContainer>
        <Label for="phone">Phone</Label>
        <Input
          id="phone"
          name="phone"
          value={inputs.phone || ""}
          onChange={handleChange}
        />
      </InputContainer>
      <InputContainer>
        <Label for="mobilePhone">Mobile phone</Label>
        <Input
          id="mobilePhone"
          name="mobilePhone"
          value={inputs.mobilePhone || ""}
          onChange={handleChange}
        />
      </InputContainer>
      <InputContainer>
        <Label for="photo">Photo</Label>
        <Input
          type="file"
          id="photo"
          name="photo"
          accept="image/png, image/jpeg"
          value={inputs.photo || ""}
          onChange={handleChange}
          required
        />
      </InputContainer>
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default SignatureMobileCareForm;
