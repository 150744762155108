import React, { useState, useEffect } from "react";
import axios from "axios";
import SignatureTypeComponent from "../components/Signature/index";
import SignatureHealthGroup from "../components/Signature/components/healthGroup/Signature";
import SignatureMobileCare from "../components/Signature/components/mobilecare/Signature";
import {
  SignatureGeneratorContainer,
  SignatureContainer,
  Button,
} from "../styles/pages/signature-generator";
import { API_URI } from "../config";

const SignatureGenerator = () => {
  const [inputs, setInputs] = useState({});
  const [submitSucces, setSubmitSucces] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [signatureSelectedOption, setSignatureSelectedOption] = useState("");
  const signatureTypes = {
    healthGroup: "Life Health Group",
    mobileCare: "Life Mobile Care",
  };
  const handleSignatureType = (value) => {
    if (value !== "") {
      setSignatureSelectedOption(value);
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;
    if (event.target.files) {
      const file = event.target.files[0];
      setInputs((values) => ({ ...values, file }));
    }
    const { value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const body = { fileName: inputs.name, file: fileReader.result };
        const result = await axios.post(
          `${API_URI}/api/home-care/upload-file`,
          body
        );
        setInputs((oldInputs) => ({
          ...oldInputs,
          fileUrl: result?.data?.result?.url,
        }));

        setSubmitSucces(true);
        setSubmitLoading(false);
      } catch (err) {
        setSubmitError(true);
        setSubmitLoading(false);
      }
    };
    fileReader.readAsDataURL(inputs.file);
  };

  const handleCopyToClipboard = () => {
    const r = document.createRange();
    r.selectNode(document.getElementById("signature"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    window.alert("Copied signature");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [submitError, submitLoading, submitSucces]);

  if (submitLoading) {
    return (
      <SignatureGeneratorContainer>
        <div>Loading...</div>
      </SignatureGeneratorContainer>
    );
  }

  if (submitError) {
    return (
      <SignatureGeneratorContainer>
        <div>We got an error... please try again later</div>
      </SignatureGeneratorContainer>
    );
  }

  return (
    <SignatureGeneratorContainer>
      {!submitSucces && (
        <SignatureTypeComponent
          handleOnSubmit={handleOnSubmit}
          handleChange={handleChange}
          inputs={inputs}
          signatureSelectedOption={signatureSelectedOption}
          signatureTypes={signatureTypes}
          handleSignatureType={handleSignatureType}
        />
      )}
      {submitSucces && (
        <SignatureContainer>
          {signatureSelectedOption === signatureTypes.healthGroup ? (
            <SignatureHealthGroup inputs={inputs} />
          ) : (
            <SignatureMobileCare inputs={inputs} />
          )}
          <Button onClick={handleCopyToClipboard}>Copy to clipboard</Button>
        </SignatureContainer>
      )}
    </SignatureGeneratorContainer>
  );
};

export default SignatureGenerator;
